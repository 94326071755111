/**
 * Returns the base URL (protocol and hostname) of a given URL
 * @param url the URL to extract the base URL from
 * @param protocol when specified, overrides the protocol of the URL
 */
export function getBaseUrl(url: string, protocol?: string) {
  const parsedUrl = new URL(url)
  if (protocol) {
    // in URL, protocol includes the colon - add it if it's missing
    protocol = protocol.endsWith(':') ? protocol : protocol + ':'
  }
  return `${protocol || parsedUrl.protocol}//${parsedUrl.hostname}`
}

export function getPathFromUrl(url: string): string {
  return url.split(/[?#]/)[0]
}

export function getFragmentFromUrl(url: string): string | undefined {
  return url.split('#')[1]
}

export function unlocalize(relativePath: string, locale: string): string {
  if (isAbsoluteUrl(relativePath)) {
    throw RangeError(`Expecting ${relativePath} to be a relative path`)
  }

  const path = new URL(relativePath, 'https://domain').pathname

  if (path.startsWith(`/${locale}`)) {
    return path.slice(`/${locale}`.length)
  }

  return path
}

export function isAbsoluteUrl(route: string): boolean {
  return new RegExp('^(?:[a-z]+:)?//', 'i').test(route)
}

export function parseUrl(urlString: string): URL {
  if (urlString.startsWith('//')) {
    urlString = `https:${urlString}`
  }
  return new URL(urlString)
}
